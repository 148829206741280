import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { Grid, Card } from '@paygreen/paygreen-ui';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import { parseGraphQL } from '../utils/parsers/questionsParser';
import QuestionList from '../components/QuestionList';
import SEO from '../components/seo';
import { sortPrismicItemByPriority } from '../utils/arrayHelper';

const CategoryPage = ({ data, pageContext, ...rest }) => {
    const intl = useIntl();
    const category = data.prismic.category || null;
    if (!category) {
        return null;
    }

    const colorTheme =
        category.colorTheme === 'default' ? 'primary' : category.colorTheme;
    const gradient = category.colorTheme === 'default' ? 'brand' : 'theme';

    let questions = data.prismic.allQuestions.edges;
    questions = sortPrismicItemByPriority(questions);
    questions = questions.map(({ node }) => parseGraphQL(node));

    if (questions.lenght === 0) {
        return null;
    }

    const {
        breadcrumbLabel,
        metaTitle,
        metaDescription,
        ogUrl,
        ogTitle,
        ogDescription,
        ogImage,
    } = data.prismic.category;

    const breadcrumb = [
        {
            url: '/',
            label: intl.formatMessage({ id: 'home.title' }),
        },
        {
            url: '/' + category.parentTheme._meta.uid + '/',
            label: category.parentTheme.breadcrumbLabel,
        },
        {
            url: '#',
            label: breadcrumbLabel,
        },
    ];

    return (
        <Layout colorTheme={colorTheme}>
            <SEO
                metaTitle={metaTitle}
                description={metaDescription}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogUrl={ogUrl}
            />

            <Banner
                breadcrumb={breadcrumb}
                name={category.name}
                description={category.description}
                theme={colorTheme}
                gradient={gradient}
            />

            <Grid
                justifyContent="center"
                childrenMargin="none"
                childrenMarginBig="none"
            >
                <Card hasBackground={false} blockWidth="xl">
                    <QuestionList
                        questions={questions}
                        colorTheme={colorTheme}
                    />
                </Card>
            </Grid>
        </Layout>
    );
};

export default CategoryPage;

export const query = graphql`
    query($uid: String!, $id: String!, $tagNameToProduction: [String!]) {
        prismic {
            category(uid: $uid, lang: "fr-fr") {
                breadcrumbLabel
                name
                description
                colorTheme
                metaTitle
                metaDescription
                canonicalUrl
                ogUrl
                ogTitle
                ogDescription
                ogImage
                _meta {
                    uid
                }
                parentTheme {
                    ... on PRISMIC_Theme {
                        _meta {
                            uid
                        }
                        breadcrumbLabel
                        name
                        icon
                        colorTheme
                    }
                }
            }
        }
        prismic {
            allQuestions(
                where: { parentCategory: $id }
                tags_in: $tagNameToProduction
            ) {
                edges {
                    node {
                        title
                        description
                        priority
                        _meta {
                            uid
                        }
                        parentCategory {
                            ... on PRISMIC_Category {
                                _meta {
                                    uid
                                }
                                parentTheme {
                                    ... on PRISMIC_Theme {
                                        _meta {
                                            uid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
